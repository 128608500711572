


























import { Component, Vue } from 'vue-property-decorator';
import { AuthService } from '@/services/AuthService';

@Component
export default class ResetPassword extends Vue {
  email = '';
  authService = new AuthService();

  async resetPassword() {
    try {
      await this.authService.resetPassword(this.email);
      this.$router.push({ name: 'Login' });
      this.$showMessage(this.$t('resetPassword.message'));
    } catch (e) {
      this.$showError(this.$t('login.notSuccessful'));
    }
  }
}
